import Vue from 'vue';
import Desktop from './Desktop.vue';
import Mobile from './Mobile.vue';
import Warn from './Warn.vue';
import socket from './socket.js';
import('@/assets/style/main.scss');

Vue.config.productionTip = false;
document.title = 'Keno';

const getVariable = async () => {
  const response = await fetch('https://bts24.co/api/keno/config');
  const data = await response.json();
  return data;
};

getVariable().then(panic => {
  let mode;

  if (panic) {
    mode = Warn;
  } else {
    mode = (window.innerWidth < 500) ? Mobile : Desktop;
  }
  
  socket.on('connect', () => {
    new Vue({
      data: {
        dynamicComponent: mode
      },
      render: function (h) {
        return h(this.dynamicComponent);
      },
      mounted() {
        socket.on('paused', (param) => {
          if (param) {
            this.dynamicComponent = Warn;
          }
        });
        socket.on('kicked', (param) => {
          if (param) {
            this.dynamicComponent = Warn;
          }
        });
      },
    }).$mount('#app');
  }).on('connect_error', () => {
    const errorMessage = document.createElement('div');
    errorMessage.textContent = 'Failed to connect to the server. No token or invalid token provided.';
    document.body.appendChild(errorMessage);
    document.body.style.backgroundColor = 'white';
  });
});