<template>
  <div class="tabs">
    <div class="tabs__nav">
      <div :class="{'active': active == 'betting'}" v-on:click="active = 'betting'">Betting</div>
      <div :class="{'active': active == 'tickets'}"  v-on:click="active = 'tickets'">Tickets</div>
      <div :class="{'active': active == 'statistics'}"  v-on:click="active = 'statistics'">Statistics</div>
    </div>
    <Betting v-show="active == 'betting'"></Betting>
    <Statistics v-show="active == 'statistics'"></Statistics>
    <Tickets v-show="active == 'tickets'"></Tickets>
  </div>
</template>
<script>
import socket from '@/socket.js';

import Betting from './tabs/Betting.vue';
import Statistics from './tabs/Statistics.vue';
import Tickets from './tabs/Tickets.vue';

export default {
  name: 'TabsMob',
  components: {
    Statistics,
    Betting,
    Tickets
  },
  data() {
    return {
      active: 'betting'
    }
  },
  mounted() {
    socket.on("drawing", (data) => {
      this.active = 'tickets'
    });
  }
}
</script>