<template>
  <div class="jackpot">
    <div class="jackpot__left">
      <div class="jackpot__jackpot">
        <img src="./../assets/images/jackpot.png" alt="">
        <div class="jackpot__numbers">{{jackpot}}</div>
      </div>
      <div class="jackpot__quickjack">
        <img src="./../assets/images/quickjack.png" alt="">
        <div class="jackpot__numbers">{{ quick }}</div>
      </div>
    </div>
    <div class="jackpot__right">
      <img src="./../assets/images/megajackpot.png" alt="">
      <div class="jackpot__numbers jackpot__numbers--mega">{{ mega }}</div>
    </div>
  </div>
</template>
<script>
import socket from '@/socket';

export default {
  name: 'Jackpot',
  data() {
    return {
      jackpot: 0.0000000,
      mega: 0.0000000,
      quick: 0.0000000
    }
  },
  mounted() {
    socket.on("jackpot", (data) => {
      data.value = data.value.toFixed(2);
      this.jackpot = this.formatJackpot(data.value, 9);
    });
    socket.on("quickJackpot", (data) => {
      data.value = data.value.toFixed(2);
      this.quick = this.formatJackpot(data.value, 9);
    });
    socket.on("megaJackpot", (data) => {
      data.value = data.value.toFixed(2);
      this.mega = this.formatJackpot(data.value, 11);
    });
  },
  methods: {
    formatJackpot(value, maxLength) {
      var formattedValue = value.toString();
      
      if (formattedValue.length > maxLength) {
        return formattedValue.slice(0, maxLength);
      } else {
        var temp = maxLength - formattedValue.length;
        for (let i = 0; i < temp; i++) {
          formattedValue = '0' + formattedValue;
        }
        return formattedValue;
      }
    }
  }
}
</script>