<template>
  <div class="statistics">
    <h1 class="statistics__title">Round results</h1>
    <div>
      <div class="statistics__round" v-for="round in statistics">
        <div class="statistics__round__header">
          <div class="statistics__round--round">Round: &nbsp;</div>
          <div class="statistics__round--id">{{ round.round_number }}</div>
        </div>
        <div class="statistics__round__header">
          <div class="statistics__round--round" style="width: 100%;">{{ round.ends_at | dateFormat }}</div>
        </div>
        <div class="statistics__round__numbers">
          <ul>
            <li v-for="n in round.drawn_numbers">{{ n }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import socket from '@/socket.js';

export default {
  name: 'Statistics',
  data() {
    return {
      statistics: []
    }
  },
  filters: {
    dateFormat (value) {
      return moment(value).format('DD/MM/YYYY HH:mm:ss');
    }
  },
  created() {
    socket.on("statistics", (data) => {
      this.statistics = data;
    })
  }
}
</script>