<template>
  <div class="app" :class="{'loaderH': loading}">
    <div class="loading" v-show="loading"></div>
    <div v-show="!loading">
      <Header></Header>
      <div class="app__live">
        <div class="app__live__board">
          <Jackpot></Jackpot>
          <Table></Table>
          <RoundCount></RoundCount>
        </div>
      </div>
      <div style="background: black; padding: 2px;"></div>
      <Infos></Infos>
      <TabsMob></TabsMob>
      <PopUp :info="info" v-if="inactive"></PopUp>
    </div>
  </div>
</template>
<script>
import Header from './views/Header.vue';
import Jackpot from './views/Jackpot.vue';
import Table from './views/Table.vue';
import RoundCount from './views/RoundCount.vue';
import Infos from './views/Infos.vue';
import TabsMob from './views/TabsMob.vue';
import PopUp from './views/PopUp.vue';

export default {
  name: 'Mobile',
  components: {
    Header,
    Jackpot,
    Table,
    RoundCount,
    Infos,
    TabsMob,
    PopUp
  },
  data() {
    return {
      loading: true,
      inactivityTimer: null,
      inactive: false,
      info: {
        text: "Please reload!",
        type: 'red'
      }
    }
  },
  created() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  mounted() {
    this.startInactivityTimer();
    document.addEventListener('mousedown', this.resetInactivityTimer);
    document.addEventListener('keydown', this.resetInactivityTimer);
  },
  methods: {
    handleVisibilityChange() {
      if (!document.hidden) {
        location.reload();
      }
    },
    startInactivityTimer() {
      setTimeout(() => {
        this.loading = false;
      }, 1000)
      this.inactivityTimer = setTimeout(() => {
        this.inactive = true;
      }, 600000);
    },
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimer);
      this.startInactivityTimer();
    }
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.resetInactivityTimer);
    document.removeEventListener('keydown', this.resetInactivityTimer);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }
}
</script>