<template>
  <div class="app">
    <div class="loading" v-show="loading"></div>
    <div class="desktop" v-show="!loading">
      <Header></Header>
      <div class="desktop__comp">
        <div class="app__live">
          <div class="app__live__board">
            <Jackpot></Jackpot>
            <Table></Table>
            <RoundCount></RoundCount>
          </div>
          <Infos></Infos>
        </div>
        <div class="app__bets">
          <div class="tabs">
            <Betting></Betting>
          </div>
          <TabsDesk style="overflow: hidden;"></TabsDesk>
        </div>
      </div>
      <PopUp :info="info" v-if="inactive"></PopUp>
    </div>
  </div>
</template>
<script>
import Header from './views/Header.vue';
import Jackpot from './views/Jackpot.vue';
import Table from './views/Table.vue';
import RoundCount from './views/RoundCount.vue';
import Infos from './views/Infos.vue';
import Betting from './views/tabs/Betting.vue';
import TabsDesk from './views/TabsDesk.vue';
import PopUp from './views/PopUp.vue';
export default {
  name: 'Desktop',
  data() {
    return {
      currentWidth: 0,
      currentHeight: 0,
      loading: true,
      inactivityTimer: null,
      inactive: false,
      info: {
        text: "Please reload!",
        type: 'red'
      }
    }
  },
  components: {
    Header,
    Jackpot,
    Table,
    RoundCount,
    Infos,
    Betting,
    TabsDesk,
    PopUp
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  mounted() {
    this.startInactivityTimer();
    this.handleResize();
    document.addEventListener('mousedown', this.resetInactivityTimer);
    document.addEventListener('keydown', this.resetInactivityTimer);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    document.removeEventListener('mousedown', this.resetInactivityTimer);
    document.removeEventListener('keydown', this.resetInactivityTimer);
  },
  methods: {
    handleVisibilityChange() {
      if (!document.hidden) {
        location.reload();
      }
    },
    startInactivityTimer() {
      this.currentWidth = 1600;
      this.currentHeight = 900;
      setTimeout(() => {
        this.loading = false;
      }, 1000)
      this.inactivityTimer = setTimeout(() => {
        this.inactive = true;
      }, 600000);
    },
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimer);
      this.startInactivityTimer();
    },
    handleResize() {
      const componentsDiv = document.querySelector('.desktop');

      componentsDiv.style.height = this.currentHeight + 'px';
      componentsDiv.style.minHeight = this.currentHeight + 'px';
      componentsDiv.style.width = this.currentWidth + 'px';
      componentsDiv.style.minWidth = this.currentWidth + 'px';
      if ( window.innerWidth / this.currentWidth > window.innerHeight / this.currentHeight ) {
        let size = window.innerHeight / this.currentHeight
        componentsDiv.style.transform = `scale(${size})`
      } else {
        let size = window.innerWidth / this.currentWidth
        componentsDiv.style.transform = `scale(${size})`
      }
    }
  }
}
</script>