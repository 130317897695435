<template>
  <div class="tabs">
    <div class="tabs__nav">
      <div :class="{'active': active == 'tickets'}"  v-on:click="active = 'tickets'">Tickets</div>
      <div :class="{'active': active == 'statistics'}"  v-on:click="active = 'statistics'">Statistics</div>
    </div>
    <Statistics v-show="active == 'statistics'"></Statistics>
    <Tickets v-show="active == 'tickets'"></Tickets>
  </div>
</template>
<script>
import socket from '@/socket.js';

import Statistics from './tabs/Statistics.vue';
import Tickets from './tabs/Tickets.vue';

export default {
  name: 'TabsDesk',
  components: {
    Statistics,
    Tickets
  },
  data() {
    return {
      active: 'tickets'
    }
  },
  mounted() {
    socket.on("drawing", (data) => {
      this.active = 'tickets'
    });
  }
}
</script>