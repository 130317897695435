<template>
  <div class="app">
    <div class="warn">
      <h3>
        We are currently experiencing issues. Please try again later.
      </h3>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Warn',
}
</script>