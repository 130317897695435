import { io } from 'socket.io-client';
const token = new URL(location.href).searchParams.get('token');
const operatorId = new URL(location.href).searchParams.get('operatorId');
const socket = io('https://bts24.co/keno', {
path: '/socket.io',
    extraHeaders: {
        Authorization: `Bearer ${token}`,
        operatorId: `${operatorId}`,
      }
  });

export default socket;
