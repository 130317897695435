<template>
  <div class="infos" v-if="infos">
    <span>{{ infos.participants.length }}</span> Jackpot players - Winners: 
    <span>{{ infos.type[show] }} &nbsp;</span>
    <span>{{ infos.winners[show] }} &nbsp;</span>
    <span>{{ infos.amount[show] }}.00 EUR</span>
  </div>
</template>
<script>
import socket from './../socket';

export default {
  name: 'Infos',
  data() {
    return {
      infos: {
        participants: [],
        winners: ['dafi1', 'gizaa', 'jonpr'],
        type: ['MEGA JACKPOT', 'QUICKJACK', 'JACKPOT'],
        amount: [208, 140, 80]
      },
      show: 0
    }
  },
  mounted() {
    socket.on('panel', data => {
      this.infos = data;
      this.infos.winners = ['dafi1', 'gizaa', 'jonpr']
      this.infos.type = ['MEGA JACKPOT', 'QUICKJACK', 'JACKPOT'];
      this.infos.amount = [208, 140, 80];
    })
    setInterval(() => {
      if (this.show == (this.infos.winners.length - 1)) {
        this.show = 0;
      } else {
        this.show = this.show + 1;
      }
    }, 3000);
  }
}
</script>