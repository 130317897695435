<template>
  <div class="table">
    <div>
      <div class="table__infos">
        <div class="table__infos__title">Wintable {{drawn_numbers.length}}/{{winTable}}</div>
        <div class="table__infos__numbers">
          <div>
            <p>HITS</p>
            <p v-for="n in 10">{{ 11 - n }}</p>
          </div>
          <div>
            <p>No#</p>
            <p v-for="(hit, index) in Object.values(winTableHits).reverse()" :key="index">
              <span v-if="hit >= 1">{{ Math.round(hit) }}</span>
              <span v-else>&nbsp;</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="table__numbers">
      <div class="table__number" v-for="n in 80" :class="{'active': drawn_numbers.includes(n)}">
        <p>{{ n }}</p>
      </div>
    </div>
    <div>
      <div class="table__infos">
        <div class="table__infos__title">Playtable {{activePlaytable}} NUM</div>
        <div class="table__infos__numbers">
          <div>
            <p>HITS</p>
            <p v-for="n in 10">{{ 11 - n }}</p>
          </div>
          <div>
            <p>WIN</p>
            <p v-for="n in playTable[activePlaytable]">{{ n }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import 'moment-timezone';
import socket from './../socket';

export default {
  name: 'Table',
  data() {
    return {
      drawn_numbers: [],
      playTable: {
        1: ['-', '-', '-', '-', '-', '-', '-', '-', '-', 'x3.49'],
        2: ['-', '-', '-', '-', '-', '-', '-', '-', 'x4.92', 'x1.5'],
        3: ['-', '-', '-', '-', '-', '-', '-', 'x8.2', 'x2.3', 'x1'],
        4: ['-', '-', '-', '-', '-', '-', 'x21', 'x4.2', 'x1.82', 'x0.5'],
        5: ['-', '-', '-', '-', '-', 'x35', 'x15', 'x3.75', 'x1.1', '-'],
        6: ['-', '-', '-', '-', 'x55', 'x18', 'x7.6', 'x2.9', 'x0.5', '-'],
        7: ['-', '-', '-', 'x60', 'x31', 'x10', 'x4.1', 'x2.3', 'x0.25', '-'],
        8: ['-', '-', 'x70', 'x40', 'x28', 'x11.4', 'x2.8', 'x1.4', '-', '-'],
        9: ['-', 'x85', 'x55', 'x25', 'x17', 'x6.1', 'x2.2', 'x1', '-', '-'],
        10: ['x100', 'x75', 'x40', 'x25', 'x10.2', 'x3.3', 'x1.5', 'x1', '-', '-'],
      },
      activePlaytable: 1,
      winTableHits: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0
      },
      winTable: 20,
      lastDraw: [],
      connecting: true,
      maxes: []
    }
  },
  watch: {
    drawn_numbers(newVal) {
      if (newVal.length > 21) {
        location.reload();
      }
    }
  },
  mounted() {
    this.getPaytable();
    this.drawingInfo();
    this.firstJoin();
  },
  methods: {
    getPaytable() {
      socket.on('payTable', (socketData) => {
        for (let key in socketData) {
          let numericKey = parseInt(key);

          for (let nestedKey in socketData[key]) {
            let numericNestedKey = parseInt(nestedKey);
            let arrayIndex = this.playTable[numericKey].length - numericNestedKey;
            if (socketData[key][nestedKey] != 0) {
              this.playTable[numericKey][arrayIndex] = `x${(socketData[key][nestedKey]).toFixed(2)}`;
            } else {
              this.playTable[numericKey][arrayIndex] = `-`;
            }
          }
        }

        setInterval(() => {
          this.activePlaytable = (this.activePlaytable % 10) + 1;
        }, 5000);
      });
    },
    drawingInfo() {
      socket.on("winTable", (data) => {
        this.winTableHits = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
          8: 0,
          9: 0,
          10: 0
        };
        this.maxes = Object.values(data);
      });
      socket.on("drawing", (data) => {
        if (!this.connecting) {
          this.drawn_numbers = [];
          this.winTable = 0;
          this.winTable = data.drawn_numbers.length;
          let currentIndex = 0;
          const interval = setInterval(() => {
            if (currentIndex < data.drawn_numbers.length) {
              this.drawn_numbers.push(data.drawn_numbers[currentIndex]);
              currentIndex++;
              if(currentIndex < (data.drawn_numbers.length -1)) {
                this.updateWintable();
              } else {
                this.winTableInfo();
              }
            } else {
              clearInterval(interval);
            }
          }, 1400);
        }
      });
    },
    firstJoin() {
      socket.on("round", (data) => {
        if (this.connecting) {
          const endsAtTime = moment(data.ends_at);
          const currentTime = moment().tz('Europe/Amsterdam');
          socket.on("drawing", (data) => {
            if (this.connecting) {
              this.connecting = false;
              this.lastDraw = data.drawn_numbers;
              this.winTable = data.drawn_numbers.length;
              const timeDifference = endsAtTime.diff(currentTime, 'milliseconds');
              if (timeDifference <= 0) {
                const seconds = moment.duration(timeDifference).seconds();
                var finnished = Math.round(seconds / (-1.4));
                for (let i = 0; i < finnished; i++) {
                  this.drawn_numbers.push(this.lastDraw[i]);
                  this.updateWintable();
                }
        
                let currentIndex = finnished;
                const interval = setInterval(() => {
                  if (currentIndex < this.lastDraw.length) {
                    this.drawn_numbers.push(this.lastDraw[currentIndex]);
                    currentIndex++;
                    if (currentIndex < (this.lastDraw.length - 1)) {
                      this.updateWintable();
                    } else {
                      this.winTableInfo();
                    }
                  } else {
                    clearInterval(interval);
                  }
                }, 1400);
              } else {
                this.drawn_numbers = this.lastDraw;
                this.winTableInfo();
              }
            }
          })
        }
      });
    },
    updateWintable() {
      for (let key = 1; key <= 7; key++) {
        const incrementValue = this.maxes[key-1] / this.winTable;

        switch (key) {
          case 1:
            this.winTableHits[key] = (this.winTableHits[key] + incrementValue + 2.33);
            break;
          case 2:
            this.winTableHits[key] = (this.winTableHits[key] + incrementValue + 2.1);
            break;
          case 3:
            this.winTableHits[key] = (this.winTableHits[key] + incrementValue + 1.71);
            break;
          case 4:
            this.winTableHits[key] = (this.winTableHits[key] + incrementValue + 1.33);
            break;
          case 5:
            this.winTableHits[key] = (this.winTableHits[key] + incrementValue + 1.1);
            break;
          case 6:
            this.winTableHits[key] = (this.winTableHits[key] + incrementValue + 0.49);
            break;
          case 7:
            this.winTableHits[key] = (this.winTableHits[key] + (incrementValue + 0.33));
            break;
        }
      };
    },
    winTableInfo() {
      this.winTableHits = {
        1: this.maxes[0],
        2: this.maxes[1],
        3: this.maxes[2],
        4: this.maxes[3],
        5: this.maxes[4],
        6: this.maxes[5],
        7: this.maxes[6],
        8: this.maxes[7],
        9: this.maxes[8],
        10: this.maxes[9]
      };
    }
  }
}
</script>