<template>
  <div class="round_count">
    <p>{{ countdown }}</p>
  </div>
</template>
<script>
import socket from '@/socket.js';
import moment from 'moment';
import 'moment-timezone';

export default {
  name: 'RoundCount',
  data() {
    return {
      countdown: ''
    };
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      socket.on("round", (data) => {
        const endsAtTime = moment(data.ends_at);

        const countdownInterval = setInterval(() => {
          const currentTime = moment().tz('Europe/Amsterdam');
          const timeDifference = endsAtTime.diff(currentTime, 'milliseconds');

          if (timeDifference <= 0) {
            clearInterval(countdownInterval);
            this.countdown = '';
            setTimeout(() => {
              this.startCountdown();
            }, 10000)
          } else {
            const minutes = moment.duration(timeDifference).minutes();
            const seconds = moment.duration(timeDifference).seconds();

            this.countdown = `${minutes}:${String(seconds).padStart(2, '0')}`;
          }
        }, 1000);
      });
    },
  } 
}
</script>
