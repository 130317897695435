<template>
  <div class="betting">
    <div class="betting__inputs">
      <div class="betting__inputs__random">
        <div class="betting__inputs__up">
          <div class="betting__inputs__text betting__inputs__text--rnd">
            <p>Quick Tip</p>
            <p>Lloji Tik.</p>
          </div>
          <div class="betting__inputs__number betting__inputs__number--rnd" v-on:click.stop="keypadRnd = !keypadRnd, keypadBet = false" v-on:click="keypadRnd = true">
            <input type="number" v-model="playNumber" readonly>
          </div>
        </div>
        <div class="betting__inputs__down betting__inputs__down--rnd" v-on:click="generateRandom">
          RANDOM <i class="fa-solid fa-arrows-rotate"></i>
        </div>
      </div>
      <div class="betting__inputs__trash">
        <div class="betting__inputs__down" v-on:click="clearTicket()">
          <i class="fa-solid fa-trash"></i>
        </div>
      </div>
      <div class="betting__inputs__bet">
        <div class="betting__inputs__up">
          <div class="betting__inputs__text betting__inputs__text--bet">
            <p>Stake</p>
            <p>Shuma</p>
          </div>
          <div class="betting__inputs__number betting__inputs__number--bet" v-on:click.stop="keypadBet = !keypadBet, keypadRnd = false" v-on:click="keypadBet = true">
            <input type="text" v-model="tempStake" readonly>
          </div>
        </div>
        <div class="betting__inputs__down betting__inputs__down--bet" v-on:click="addTicket()">
          PLACE BET
        </div>
      </div>
    </div>
    <div class="betting__keyboard" v-if="keypadRnd" v-click-outside="handleClickOutside">
      <div class="betting__pad">
        <div class="betting__pad__numbers">
          <div v-for="n in 9" v-on:click="generateRandom(n)">{{ n }}</div>
          <div>&nbsp;</div>
          <div v-on:click="generateRandom(10)">10</div>
          <div>&nbsp;</div>
        </div>
        <div class="betting__pad__options" v-on:click="keypadRnd = false">
          <div>Cancel</div>
          <div>OK</div>
        </div>
      </div>
    </div>
    <div class="betting__keyboard" v-if="keypadBet" v-click-outside="handleClickOutside">
      <div class="betting__pad betting__pad--bet">
        <div class="betting__pad__numbers">
          <div v-for="n in 9" v-on:click="updateStake(n)">{{ n }}</div>
          <div  v-on:click="updateStake('del')"><i class="fa-solid fa-arrow-left-long"></i></div>
          <div v-on:click="updateStake(0)">0</div>
          <div v-on:click="updateStake('.')">.</div>
        </div>
        <div class="betting__pad__options" v-on:click="keypadBet = false">
          <div v-on:click="confirmStake(false)">Cancel</div>
          <div v-on:click="confirmStake(true)">OK</div>
        </div>
      </div>
    </div>
    <div class="betting__table">
      <div>
        <div class="betting__numbers">
          <div v-for="n in 80" :class="{'active': ticketNumbers.includes(n)}" v-on:click="clickedNumbers(n)">{{ n }}</div>
        </div>
        <div class="betting__rows">
          <div v-on:click="rowNumbers(0)">1</div>
          <div v-for="n in 7" v-on:click="rowNumbers(n)">{{ n }}1+</div>
        </div>
      </div>
      <div class="betting__columns">
        <div v-for="n in 10" v-on:click="columnNumbers(n)">*{{ n }}</div>
        <p></p>
      </div>
    </div>
    <PopUp :info="info" v-if="info.show" @close-popup="info.show = false" @join-jackpot="joinJackpot"></PopUp>
  </div>
</template>
<script>
import socket from '@/socket.js';
import moment from 'moment';
import 'moment-timezone';

import PopUp from '../PopUp.vue';

export default {
  name: 'Betting',
  components: {
    PopUp
  },
  data() {
    return {
      keypadRnd: false,
      keypadBet: false,
      playNumber: 6,
      stake: null,
      tempStake: null,
      ticketNumbers: [],
      ticket: {},
      roundId: '',
      info: {
        show: false,
        type: 'blue',
        text: 'Bet placed'
      },
      countdown: '',
      balance: null,
      maxLimit: null,
      minLimit: null
    }
  },
  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression]();
          }
        };
        document.addEventListener('click', el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  watch: {
    tempStake(newVal) {
      if (isNaN(newVal)) {
        this.tempStake = 0;
        this.stake = 0;
      } else if ((newVal % 1 !== 0) && newVal.toString().split('.')[1].length > 2) {
        this.tempStake = newVal.toFixed(2);
      }
    }
  },
  mounted() {
    this.limitsInfo();
    this.startCountdown();
    this.userInfo();
  },
  methods: {
    limitsInfo() {
      socket.on('limits', (data) => {
        this.maxLimit = data.max_stake;
        this.minLimit = data.min_stake;
        this.stake = data.min_stake;
        this.tempStake = data.min_stake;
      });
    },
    handleClickOutside() {
      this.keypadRnd = false;
      this.keypadBet = false;
      this.confirmStake(true);
    },
    clearTicket() {
      this.ticketNumbers = [];
      this.playNumber = 6;
      this.stake = this.minLimit;
      this.tempStake = this.minLimit;
    },
    updateStake(param) {
      var strStake = this.tempStake.toString();
      var nrStake = Number(this.tempStake);

      if (param == 'del') {
        // if (nrStake > 9) {
        //   this.tempStake = parseInt(strStake.slice(0, -1));
        // }

        if (strStake.includes('.')) {
          this.tempStake = Number(strStake.slice(0, -1));
        } else {
          this.tempStake = parseInt(strStake.slice(0, -1));
        }
      }

      if (param == '.') {
        if (!strStake.includes('.')) {
          this.tempStake = strStake + '.'
        }
      }

      if (param == 0) {
        this.tempStake = Number(strStake + '0');
      
        // if (nrStake == 1 || nrStake == 2) {
        //   this.tempStake = Number(strStake + '0');
        // } else if (strStake.includes('.')) {
        //   this.tempStake = Number(strStake);
        // } else {
        //   this.tempStake = 0;
        // }
        // if (nrStake == 1 || nrStake == 2) {
        //   this.tempStake = Number(strStake + '0');
        // } else if (strStake.includes('.')) {
        //   this.tempStake = Number(strStake);
        // } else {
        //   this.tempStake = 0;
        // }
      }

      if (param >= 1) {
        this.tempStake = this.tempStake.toString() + param;
        this.tempStake = Number(this.tempStake);

        // if (strStake[strStake.length -1] == '.' || nrStake == 1) {
        //   this.tempStake = Number(strStake + param);
        // } else {
        //   this.tempStake = Number(param);
        // }
      }
    },
    confirmStake(param) {
      // if (param && this.tempStake != 0) {
        this.tempStake = Number(this.tempStake)
        this.stake = this.tempStake;
      //   } else {
      //     this.tempStake = this.stake;
      // }
    },
    clickedNumbers(param) {
      if (this.ticketNumbers.indexOf(param) !== -1) {
        this.ticketNumbers.splice(this.ticketNumbers.indexOf(param), 1);
      } else if (this.ticketNumbers.length < 10){
        this.ticketNumbers.push(param);
      }

      this.playNumber = this.ticketNumbers.length;
    },
    columnNumbers(param) {
      var ticketNumbers = [];

      for (let i = 0; i <= 7; i++) {
        if (param == 10 ) {
          const resultAsInt = (i+1)*10;
          ticketNumbers.push(resultAsInt);
        } else {
          const concatenated = `${i}${param}`;
          const resultAsInt = parseInt(concatenated, 10);
          ticketNumbers.push(resultAsInt);
        }
      }
      if (JSON.stringify(ticketNumbers) === JSON.stringify(this.ticketNumbers)) {
        this.ticketNumbers = [];
      } else {
        this.ticketNumbers = ticketNumbers;
        this.playNumber = this.ticketNumbers.length;
      }

    },
    rowNumbers(param) {
      var startRange = param*10+1;
      var endRange = param*10+10;
      var ticketNumbers = [];

      for (let i = startRange; i <= endRange; i++) {
        ticketNumbers.push(i);
      }

      if (JSON.stringify(ticketNumbers) === JSON.stringify(this.ticketNumbers)) {
        this.ticketNumbers = [];
      } else {
        this.ticketNumbers = ticketNumbers;
        this.playNumber = this.ticketNumbers.length;
      }
    },
    generateRandom(param) {
      if (typeof param === 'number') {
        this.playNumber = param;
      }

      this.ticketNumbers = [];

      while (this.ticketNumbers.length < this.playNumber) {
        const randomNumber = Math.floor(Math.random() * 80) + 1;
        if (!this.ticketNumbers.includes(randomNumber)) {
          this.ticketNumbers.push(randomNumber);
        }
      }
    },
    addTicket() {
      if (this.countdown == 'stoped') {
        this.info = {
          show: true,
          type: 'red',
          text: 'Bet has been refused. The time has passed, please wait for the next round.'
        }

        setTimeout(() => {
          this.info.show = false;
        }, 3000);
      } else if (this.balance < this.stake) {
        this.info = {
          show: true,
          type: 'red',
          text: 'Bet has been refused. Not enough credit.'
        }

        setTimeout(() => {
          this.info.show = false;
        }, 3000);
      } else if (this.stake > this.maxLimit || this.stake < this.minLimit) {
        this.info = {
          show: true,
          type: 'red',
          text: 'Min stake is ' + this.minLimit + '. Max stake is ' + this.maxLimit + '.'
        }

        setTimeout(() => {
          this.info.show = false;
        }, 3000);
      } else if (this.ticketNumbers.length) {
        var ticket = {
          roundId: this.roundId.toString(),
          username: 'test',
          spotsPlayed: this.ticketNumbers,
          stake: this.stake
        }

        this.ticket = ticket;
        socket.emit('addTicket', this.ticket );

        this.info = {
          show: true,
          type: 'blue',
          text: 'Bet placed.'
        }
        this.ticketNumbers = [];
        setTimeout(() => {
          this.info.show = false;
        }, 4500);

      } else {
        this.info = {
          show: true,
          type: 'red',
          text: 'Bet has been refused. The numbers in the combination must be from 1 to 80.'
        }
        setTimeout(() => {
          this.info.show = false;
        }, 3000);
      }
    },
    joinJackpot(param) {
      if (param == 'mega') {
        this.ticket.megaJackpot = true;
      } else {
        this.ticket.quickJackpot = true;
      }
      socket.emit('addTicket', this.ticket);
      this.info.show = false;
    },
    startCountdown() {
      socket.on("round", (data) => {
        this.roundId = data._id;
        const endsAtTime = moment(data.ends_at);
        const countdownInterval = setInterval(() => {
          const currentTime = moment().tz('Europe/Amsterdam');
          const timeDifference = endsAtTime.diff(currentTime, 'milliseconds');

          if (timeDifference <= 5000) {
            clearInterval(countdownInterval);
            this.countdown = 'stoped';
          } else {
            this.countdown = 'active';
          }
        }, 1000);
      });
    },
    userInfo() {
      socket.on("user", (data) => {
        this.balance = data.balance;
      })
    }
  }
}
</script>