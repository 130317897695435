<template>
  <div class="popup">
    <div class="popup__details">
      <div class="popup__details__header" v-on:click="$emit('close-popup')">
        INFO
        <div v-if="info.text != 'Please reload!'">⤬</div>
      </div>
      <div v-if="info.text == 'Bet placed.'" class="popup__details__content" :style="{ color: info.type == 'red' ? 'red' : '#29abe2'}">
        {{ info.text }}
        <div v-if="info.type == 'blue'">✓</div>
        <div v-else>✗</div>
      </div>
      <!-- <div v-if="info.text == 'Bet placed.'" class="jackpots">
        <div class="jackpots__part" :style="{ color: info.type == 'red' ? 'red' : '#29abe2'}">
          <img src="./../assets/images/quickjack.png">
          <div class="jackpots__quick" v-on:click="$emit('join-jackpot', 'quick')">Join for 0.5€</div>
        </div>
        <div class="popup__details__content" :style="{ color: info.type == 'red' ? 'red' : '#29abe2'}">
          {{ info.text }}
          <div v-if="info.type == 'blue'">✓</div>
          <div v-else>✗</div>
        </div>
        <div class="jackpots__part" :style="{ color: info.type == 'red' ? 'red' : '#29abe2'}">
          <img src="./../assets/images/megajackpot.png">
          <div class="jackpots__quick" v-on:click="$emit('join-jackpot', 'mega')">Join for 1€</div>
        </div>
      </div> -->
      <div v-else class="popup__details__content" :style="{ color: info.type == 'red' ? 'red' : '#29abe2'}">
        {{ info.text }}
        <div v-if="info.type == 'blue'">✓</div>
        <div v-else>✗</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PopUp',
  props: ['info']
}
</script>
