<template>
  <div class="tickets">
    <div class="tickets__filter">
      <span>Filter</span>
      <select v-model="filter">
        <option value="">All</option>
        <option value="open">Open</option>
        <option value="winner">Win</option>
        <option value="lost">Lost</option>
        <option value="canceled">Canceled</option>
      </select>
    </div>
    <div class="tickets__table">
      <div class="ticket" v-for="n in tickets" v-on:click="modal = true; ticketDetails = n">
        <div class="ticket__header">
          <div class="ticket__header--round">Round: &nbsp;</div>
          <div class="ticket__header--id">{{ n.round_number }}</div>
          <div class="ticket__header--date">{{ n.created_at | dateFormat }}</div>
        </div>
        <div class="ticket__bet">
          <div class="ticket__bet--title">Bet</div>
          <div class="ticket__bet--data" v-if="!n.is_open">
            <div class="ticket__bet--nr" v-for="spot in n.spots_played" :class="{'match': n.spots_matched.includes(spot)}">{{ spot }}</div>
          </div>
          <div class="ticket__bet--data" v-else>
            <div class="ticket__bet--nr" v-for="spot in n.spots_played" :class="{'match': drawn_numbers.includes(spot)}">{{ spot }}</div>
          </div>
        </div>
        <div class="ticket__footer">
          <div class="ticket__footer--stake">Stake</div>
          <div class="ticket__footer--data">{{ n.is_cancelled ? n.stake_cancelled : n.stake }} {{ currency }}</div>
          <div class="ticket__footer--status win" v-if="n.is_winner">WIN {{ n.reward }}  {{ currency }}</div>
          <div class="ticket__footer--status" v-if="!n.is_winner && !n.is_open && !n.is_cancelled">Lose</div>
          <div class="ticket__footer--status" v-if="n.is_cancelled">Canceled</div>
          <div class="ticket__footer--status open" v-if="n.is_open">Open</div>
        </div>
      </div>
    </div>
    <div class="popup" v-if="modal">
      <div class="tickets__details">
        <div class="tickets__details__header" v-on:click="modal = false; ticketDetails = {};">
          Close
          <div>⤬</div>
        </div>
        <div class="tickets__details__content">
          <div>
            <div class="left_tick_cont"> ????-????-????-???? </div>
            <div class="right_tick_cont"> {{ ticketDetails.created_at | dateFormat }} </div>
          </div>
          <div class="center_tick_cont"> KENO </div>
          <div class="left_tick_cont"> {{ ticketDetails.round_number }} </div>
          <div class="right_tick_cont"> {{ ticketDetails.ticket_number }} </div>
          <div class="left_tick_cont"> Pagesa </div>
          <div class="right_tick_cont"> {{ ticketDetails.stake }}  {{ currency }} </div>
          <div class="tick_edit_data">
            <div v-for="n in ticketDetails.spots_played" :class="{'match': ticketDetails.spots_matched.includes(n)}">{{ n }}</div>
          </div>
        </div>
        <div class="tickets__details__footer">
          <div v-on:click="repeatTicket()">Repeat</div>
          <div v-if="ticketDetails.is_open && countdown == 'active'" v-on:click="cancelTicket()">Cancel</div>
          <div v-else v-on:click="removeTicket()">Remove</div>
        </div>
      </div>
    </div>
    <PopUp :info="info" v-if="info.show" @close-popup="info.show = false"></PopUp>
  </div>
</template>
<script>
import socket from '@/socket.js';
import moment from 'moment';
import 'moment-timezone';

import PopUp from '../PopUp.vue';

export default {
  name: 'Tickets',
  components: {
    PopUp
  },
  data() {
    return {
      modal: false,
      tickets: [],
      ticketDetails: {},
      filter: '',
      roundId: '',
      info: {
        show: false,
        type: '',
        text: ''
      },
      countdown: '',
      drawn_numbers: [],
      connecting: true,
      lastDraw: [],
      username: '',
      currency: null
    }
  },
  filters: {
    dateFormat (value) {
      return moment(value).format('DD/MM/YYYY HH:mm:ss');
    }
  },
  watch: {
    filter(newVal, oldVal) {
      socket.emit('getTickets', {
        filter: newVal
      });
    }
  },
  created() {
    this.drawingInfo();
    this.startCountdown();
    this.firstJoin();
    this.ticketsInfo();
    this.userInfo();
  },
  methods: {
    ticketsInfo() {
      socket.on("tickets", (data) => {
        this.tickets = data;
      });
    },
    userInfo() {
      socket.on("user", (data) => {
        this.username = data.username;
        if (data.currency) {
          this.currency = data.currency;
        } else {
          this.currency = '€';
        }
      });
    },
    closeModal() {
      this.ticketsInfo();
      socket.emit('getTickets', {
        filter: this.filter
      });

      this.modal = false;
      this.ticketDetails = {};

      setTimeout(() => {
        this.info.show = false;
      }, 3000)
    },
    removeTicket() {
      var ticket = {
        id: this.ticketDetails._id
      }

      socket.emit('removeTicket', ticket );

      this.info = {
        show: true,
        type: 'blue',
        text: `Ticket with no: ${this.ticketDetails.ticket_number} has been removed successfuly.`
      }

      this.closeModal();
    },
    cancelTicket() {
      var ticket = {
        username: 'test',
        roundId: this.ticketDetails.round_id,
        ticketId: this.ticketDetails._id
      }

      socket.emit('cancelTicket', ticket );

      this.info = {
        show: true,
        type: 'red',
        text: 'Bet canceled.'
      }

      this.closeModal();
    },
    repeatTicket() {
      if (this.countdown == 'stoped') {
        this.info = {
          show: true,
          type: 'red',
          text: 'Bet has been refused. The time has passed, please wait for the next round.'
        };
      } else {
        var ticket = {
          roundId: this.roundId.toString(),
          username: 'test',
          spotsPlayed: this.ticketDetails.spots_played,
          stake: this.ticketDetails.stake
        }
  
        socket.emit('addTicket', ticket );
  
        this.info = {
          show: true,
          type: 'blue',
          text: 'Bet placed.'
        };
      }

      this.closeModal();
    },
    startCountdown() {
      socket.on("round", (data) => {
        this.roundId = data._id;
        this.filter = '';
        socket.emit('getTickets', {
          filter: this.filter
        });
        const endsAtTime = moment(data.ends_at);

        const countdownInterval = setInterval(() => {
          const currentTime = moment().tz('Europe/Amsterdam');
          const timeDifference = endsAtTime.diff(currentTime, 'milliseconds');

          if (timeDifference <= 500) {
            clearInterval(countdownInterval);
            this.countdown = 'stoped';
          } else {
            this.countdown = 'active';
          }
        }, 1000);
      });
    },
    drawingInfo() {
      socket.on("drawing", (data) => {
        if (!this.connecting) {
          this.drawn_numbers = [];
          let currentIndex = 0;
          const interval = setInterval(() => {
            if (currentIndex < data.drawn_numbers.length) {
              this.drawn_numbers.push(data.drawn_numbers[currentIndex]);
              currentIndex++;
            } else {
              clearInterval(interval);
              this.drawn_numbers = [];
            }
          }, 1400);
        }
      });
    },
    firstJoin() {
      socket.on("round", (data) => {
        if (this.connecting) {
          const endsAtTime = moment(data.ends_at);
          const currentTime = moment().tz('Europe/Amsterdam');
          socket.on("drawing", (data) => {
            if (this.connecting) {
              this.connecting = false;
              this.lastDraw = data.drawn_numbers;
              const timeDifference = endsAtTime.diff(currentTime, 'milliseconds');
              if (timeDifference <= 0) {
                const seconds = moment.duration(timeDifference).seconds();
                var finnished = Math.round(seconds / (-1.4));
                for (let i = 0; i < finnished; i++) {
                  this.drawn_numbers.push(this.lastDraw[i]);
                }
        
                let currentIndex = finnished;
                const interval = setInterval(() => {
                  if (currentIndex < this.lastDraw.length) {
                    this.drawn_numbers.push(this.lastDraw[currentIndex]);
                    currentIndex++;
                  } else {
                    clearInterval(interval);
                    this.drawn_numbers = [];
                  }
                }, 1400);
              }
            }
          })
        }
      });
    }
  }
}
</script>