<template>
  <div class="header">
    <div>{{ username }}</div>
    <div>{{ balance }} {{ currency }}</div>
  </div>
</template>
<script>
import socket from '@/socket';

export default {
  name: 'Header',
  data() {
    return {
      username: '',
      balance: null,
      currency: null
    }
  },
  mounted() {
    socket.on("user", (data) => {
      this.username = data.username;
      this.balance = data.balance;
      if (data.currency) {
        this.currency = data.currency;
      } else {
        this.currency = '€';
      }
    })
  }
}
</script>